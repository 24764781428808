import Head from "next/head";
import Link from "next/link";

function Custom404() {
    return (
        <div className='layout'>
            <Head>
                <title>Not Found</title>
            </Head>
            <div id='notfound'>
                <div className='notfound'>
                    <div className='image_wrap'>
                        <img src='/images/404-Image.svg' alt='Error404' />
                    </div>
                    <h2 className='mt-4'>
                        <b>404</b> - Page Not Found
                    </h2>
                    <p>The page you are looking for is not availale.</p>
                    <Link href='/'>
                        <a>Go to Home</a>
                    </Link>
                </div>
            </div>
        </div>
    );
    
}

export default Custom404;
